<template>  
  <div class="background_basic" >
    <v-card class="ma-2">           
      <v-toolbar dense flat color="white">
        <v-toolbar-title class="grey--text text--darken-3"><v-icon class="mr-2">description</v-icon>이벤트로그</v-toolbar-title>
        
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-btn  
          color="blue"
          text               
          @click="initialize()"
          class="font-weight-bold text-subtitle-1"
        ><v-icon big class="mr-2">refresh</v-icon>초기화</v-btn>         
        <v-btn color="blue" text @click="ExcelExpert('이벤트로그')" class = "ml-2 font-weight-bold text-subtitle-1">
          <v-icon big class="mr-2">mdi-microsoft-excel</v-icon>엑셀
        </v-btn>
        <v-btn  
          color="blue"
          text        
          class="ml-3 font-weight-bold text-subtitle-1"       
          @click="SelectChecked()"          
        ><v-icon big class="mr-2">refresh</v-icon>체크</v-btn>      


        <v-spacer></v-spacer>
        <v-text-field
          v-model="cNowSearchKeyWord"
          class="ml-3 mt-6"  
          label="Search"
          placeholder="검색어를 입력하세요."
          persistent-placeholder
          style="max-width:300px;"
          Regular
          v-on:keyup.enter="Search()"
        ></v-text-field>
        <v-btn  text icon  
        class="ml-n9"                
          @click="Search()"            
        ><v-icon>search</v-icon>
        </v-btn>  

        <v-progress-linear
            :active="loading"
            :indeterminate="loading"
            absolute
            bottom
            color="blue accent-4"
        ></v-progress-linear>          
      </v-toolbar>        
      <v-divider></v-divider>       
      <v-card tile flat class="d-flex ml-3 mr-3">                
        <div class="ml-3 mb-1 ">             
          <IronDate ref="IronDateRef" v-model="sNowSelectDate" @update="onDateRangeChange"/>
        </div>                 
        <v-divider vertical class="pl-3 " ></v-divider>

        <v-btn-toggle
          class="ml-3 mb-2 mt-2"
          v-model="zSelType"
          dense                    
          multiple>
          <v-btn>
            <v-icon>error</v-icon>
          </v-btn>

          <v-btn>
            <v-icon>description</v-icon>
          </v-btn>

          <v-btn>
            <v-icon>perm_device_information</v-icon>
          </v-btn>

          <v-btn>
            <v-icon>bug_report</v-icon>
          </v-btn>
        </v-btn-toggle>
        <v-divider vertical class="pl-3" ></v-divider>
        <v-switch v-model="NotCheck" class="pl-3 mb-n5 mt-3" label="미확인" color="black"></v-switch>          
        <v-switch v-model="FullMsg" class="pl-3 mb-n5 mt-3" label="전체 메시지" color="black"></v-switch>          
      </v-card>
                
      <v-divider></v-divider>
      
      <v-data-table :items-per-page="15" fixed-header   
        v-model="selected"
        :headers="headers"
        :items="Records"            
        item-key="iLogId"      
        show-select    
        height="calc(100vh - 240px)"
      >      
        <template v-slot:item.cType="{ item }">        
          <v-chip small label :color="getColor(item.cType)" dark >{{ item.cType }}</v-chip>        
        </template>
        <template v-slot:item.cDateTime="{ item }">
          <div :class="getFontColor(item.cType, item.cCheck)">{{ item.cDateTime }}</div>        
        </template>
        <template v-slot:item.cDeviceId="{ item }">
          <div :class="getFontColor(item.cType, item.cCheck)" >{{ item.cDeviceId }}</div>        
        </template>
        <template v-slot:item.cDeviceNm="{ item }">
          <div :class="getFontColor(item.cType, item.cCheck)" >{{ item.cDeviceNm }}</div>        
        </template>
        <template v-slot:item.cMessage="{ item }">
          <div :class="getAllmsg(FullMsg, item.cType, item.cCheck)" style="max-width:500px">{{ item.cMessage }}</div>        
        </template>
        <template v-slot:item.cCheck="{ item }">
          <div :class="getFontColor(item.cType, item.cCheck)" >{{ item.cCheck }}</div>        
        </template>

        <template v-slot:headers="props">
          <tr> <th v-for="h in props.headers" :key="h.text" class="body-2 font-weight-bold"> {{ h.text }} </th> </tr> 
        </template>



        <template v-slot:item.action="{ item }">        
          <v-icon big class="mr-0" @click="editItem(item)" >check</v-icon>                  
        </template>
        <template v-slot:no-data>        
          <p>No data available.</p>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import BasicInfo from "../BasicInfo.js";
import Util from "../Util.js";
import axios from "axios"
import {mapState} from "vuex";
import IronDate from '../components/iron28Calendar/src/IronDate.vue';
import XLSX from 'xlsx';
import EventBus from '@/eventBus.js';

export default {
  components: {        
    IronDate,
  },
  data: () => ({  
    loading: false,
    zSelType: [0, 1, 2],
    cNowSearchKeyWord : "",
    FullMsg : false,   
    SelLog : true,
    SelError : true,
    SelInfo : true,
    SelDebug : false,
    NotCheck : false,
        
    selectedDate: [],    
    sNowSelectDate :  Util.sFormatDate(Date()) + " ~ " +  Util.sFormatDate(Date()),
    sStartDate : Util.sFormatDate(Date()),
    sEndDate :Util.sFormatDate(Date()),
    isEditMode : false,
    sNowContentId : "ALL",
    sNowDeviceId : "ALL",
    sNowContractId : "ALL",


    iDelIndex: 0,
    

    selected: [],
    headers: [      
      {
        class: "font-weight-bold subtitle-2", 
        text: "일자",
        align: "center",        
        value: "cDateTime"
      },            
      { class: "font-weight-bold subtitle-2", text: "유형", value: "cType", align: "center"} ,
      { class: "font-weight-bold subtitle-2", text: "서버", value: "cDeviceId", } ,
      { class: "font-weight-bold subtitle-2", text: "서버명", value: "cDeviceNm",},
      { class: "font-weight-bold subtitle-2", text: "메시지", value: "cMessage", },
      { class: "font-weight-bold subtitle-2", text: "확인", value: "cCheck",},
      { class: "font-weight-bold subtitle-2", text: "체크", value: "action", sortable: false, align: "center", width:100,},
    ],
    Records: [],
    editedIndex: -1,
    editedItem: {
      value :"",
      cSiteId: "",  
      cDateTime :"",
      cType :"",
      cDeviceId :"",
      cDeviceNm :"",
      cMessage :"",
      cCheck :"",      
      iLogId: "",        
    },
  }),

  computed: {
     ...mapState(["ProgramPremissionInfo", "UserInfo"]),
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    }
  },

  watch: {
    dialog(val) {
      val || this.close();
    }
  },

  mounted() {    
    this.initialize();       
  },

  methods: {    
    SelectChecked(){

      if (this.selected.length ==0) {
        EventBus.$emit("onShowMsg",true, "No data selected.") 
        return;
      }

      var zNowSel = [];

      for(var i = 0; i < this.selected.length; i++){
        zNowSel.push(this.selected[i].iLogId);                
      } 

      var posData = {        
        cUserId : this.$store.state.UserInfo.UserId,           
        iLogId : 0,
        zNowSel : zNowSel,
      }

      axios        
        .post(BasicInfo.UIL_API + "CheckSysEventLog", posData, {
             headers: {
            "Authorization": this.$store.state.UserInfo.Token            
             }
           })
        .then(res => {           
          if (res.data.ResultCd == "44") { EventBus.$emit("onTokenExpired"); return; }
                                     
          if (res.data.ResultCd === "00") {                 
            for(var i = 0; i < this.selected.length; i++){
              for(var j = 0; j < this.Records.length; j++){
                if (this.selected[i].iLogId ===  this.Records[j].iLogId) {
                  this.Records[j].cCheck = "Y";
                  break;
                }
              }           
            }        

            this.selected = [];

          } else {
            EventBus.$emit("onShowMsg",true, res.data.Msg)
          }
        }).catch(err =>{
          EventBus.$emit("onShowMsg",true, ""+err);
        })            
    },    
    initialize() {            
      this.sNowSelectDate = Util.sFormatDate(Date()) + " ~ " +  Util.sFormatDate(Date());   
      this.$refs.IronDateRef.SetDate(this.sNowSelectDate);
      this.sStartDate = Util.sFormatDate(Date());
      this.sEndDate = Util.sFormatDate(Date());
      this.cNowSearchKeyWord = "";
      this.zSelType = [0, 1, 2];
      this.NotCheck = false;
      this.FullMsg = false;

      this.Search();
    },
    onDateRangeChange(daterange){            
      this.sStartDate = Util.sFormatDate(daterange.startDate);
      this.sEndDate = Util.sFormatDate(daterange.endDate);      
      this.sNowSelectDate = this.sStartDate + " ~ " +  this.sEndDate;           
    },
    editItem(item) {
      this.isEditMode = true;
      this.editedIndex = this.Records.indexOf(item);
      this.editedItem = Object.assign({}, item);    
        
      var posData = {
        cUserId : this.$store.state.UserInfo.UserId,   
        cSiteId : this.editedItem.cSiteId,
        iLogId : this.editedItem.iLogId,
      }

      axios
        .post(BasicInfo.UIL_API + "CheckSysEventLog", posData , {
             headers: {
            "Authorization": this.$store.state.UserInfo.Token            
             }
           })
        .then(res => {            
          if (res.data.ResultCd == "44") { EventBus.$emit("onTokenExpired"); return; }
                                    
          if (res.data.ResultCd === "00") {   
              this.editedItem.cCheck = "Y";               
              Object.assign(this.Records[this.editedIndex], this.editedItem);            
          } else {
            EventBus.$emit("onShowMsg",true, res.data.Msg)
          }
        }).catch(err =>{
          EventBus.$emit("onShowMsg",true, ""+err);
        })              
    },


    getFontColor (cType, cCheck) {      
      if (cCheck == "Y"){        
        return 'gray--text font-weight-light'
      } else {
        if (cType == "Error") return 'red--text font-weight-regular'
        else if (cType == "Log") return 'teal--text text--accent-5 font-weight-regular'
        else if (cType == "Info") return 'blue--text font-weight-regular'
        else return 'black--text font-weight-regular'
      }
    },    
    
    getColor(d) {
      if (d === "OK") {
        return "#70bbfe"
      } else if (d === "Error") {
        return "#ff4862"
      } else if (d === "Warn") {
        return "#f6da6d"        
      } else if (d === "Disconnect") {
        return "#F44336"        
      } else if (d === "ON") {
        return "#70bbfe"
      } else if (d === "Log") {
        return "#4ce1b7"
      } else if (d === "Info") {
        return "#70bbfe"
      } else if (d === "OFF") {
        return "#ff4862"
      } else if (d === "") {
        return "#FFFFFF"
      } else {
        return "#444444"
      }
    },
    
    
    getAllmsg(FullMsg, cType, cCheck) {      
      var sRet = this.getFontColor(cType, cCheck);

      if (FullMsg === true) {
        return sRet;
      } else {
        return sRet + " " + "col-2 text-truncate";        
      }
    },

    Search(){
      this.Records = [        
      ];

      var SelError = false;
      var SelLog = false;
      var SelInfo = false;
      var SelDebug = false;
      
      
      for (var i = 0; i < this.zSelType.length; i++){
        if (this.zSelType[i] === 0) {SelError = true;}
        if (this.zSelType[i] === 1) {SelLog = true;}
        if (this.zSelType[i] === 2) {SelInfo = true;}
        if (this.zSelType[i] === 3) {SelDebug = true;}
      }
      

      this.loading = true;                            
      var posData = {        
        cUserId : this.$store.state.UserInfo.UserId,             
        cStartDatetime: this.sStartDate + " 00:00:00",  
        cEndDatetime  : this.sEndDate + " 23:59:59",                  
        bSelLog       : SelLog,
        bSelError     : SelError,
        bSelInfo      : SelInfo,
        bSelDebug     : SelDebug,    
        bNotCheck     : this.NotCheck,   
        cNowSearchKeyWord : this.cNowSearchKeyWord,
      }
      axios
        .post(BasicInfo.UIL_API + "GetSysEventLog", posData, {
            headers: {
            "Authorization": this.$store.state.UserInfo.Token                        
            }
          })
        .then(res => {                    
          if (res.data.ResultCd == "44") { EventBus.$emit("onTokenExpired"); return; }
          
          if (res.data.ResultCd === "00") {
            this.Records = res.data.Result;                
          } else {
            EventBus.$emit("onShowMsg",true,  "(" + res.data.ResultCd + ") " + res.data.Msg);
          }
          this.loading = false;                      
        }).catch(err =>{
          EventBus.$emit("onShowMsg",true, ""+err);
          this.loading = false;                      
        })         
    },
    ExcelExpert(name){
      var r = Util.GetExcel(this.Records, this.headers);
      var animalWS = XLSX.utils.json_to_sheet(r)       
      var wb = XLSX.utils.book_new() // make Workbook of Excel
      XLSX.utils.book_append_sheet(wb, animalWS, name) // sheetAName is name of Worksheet      
      XLSX.writeFile(wb, name+ '-'+Util.sNowDate() + '.xlsx'); // name of the file is 'book.xlsx'
    },    
  }
};

//달력
</script>
<style> .calCSS .reportrange-text[data-v-8cc9549e] { border: none; padding:0px; height:2px }         
</style> 


